<ngx-spinner bdColor="rgb(255,0,0)" size="default" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- <div class="col-md-12">
        <app-horizontal-ad></app-horizontal-ad>
    </div> -->
<div class="row mt-4">
    <div class="col-md-9 mt-3 mt-md-0 mb-4 mb-md-0">
        <app-banner></app-banner>
    </div>
    <div class="col-md-3">
        <app-spotlight></app-spotlight>
    </div>
</div>
<div class="row">
    <div class="col-md-9">
        <app-home-news></app-home-news>
        <app-home-events></app-home-events>
    </div>
    <div class="col-md-3">
        <app-home-resources></app-home-resources>
    </div>
</div>