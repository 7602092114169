
<!-- <div class="row d-none d-md-flex" *ngIf="singleGuestAuthorsLoaded">
    <div class="col-md-6 mb-6" *ngFor="let singleGuestAuthorsData of singleGuestAuthorsDatas">
        <div class="cursor-p" (click)="refreshReq(singleGuestAuthorsData.ID)" routerLink='/resources/articles/{{singleGuestAuthorsData.RouteURL}}/{{singleGuestAuthorsData.ID}}'>
            <div class="row">
                <h4 class="text-height-2 text-uppercase" [innerHTML]="guestAuthorsData.AuthorName"></h4>
                <div class="col-md-5">
                    <img class="img-fluid pb-2" [src]="singleGuestAuthorsData.ImageUrl" alt="Responsive image">
                </div>

                <div class="col-md-7 mb-0">
                    <h2 class="para-2 mb-1">{{singleGuestAuthorsData.JobTitle}}</h2>
                    <p class="para-6 mb-0 text-justify" [innerHTML]="singleGuestAuthorsData.Description"></p>

                </div>

                <div class="row col-md-3 mb-5">
                    <p class="para-2 mb-1 text-uppercase">Articles By {{singleGuestAuthorsData.AuthorName}}</p>

                    <h2 class="text-height-2 text-uppercase" [innerHTML]="singleGuestAuthorsData.Title"></h2>

                    <p class="para-2 mb-1 text-uppercase">Articles By {{singleGuestAuthorsData.PublishingDate}}</p>

                    <p class="para-2 mb-1 text-uppercase">Articles By {{singleGuestAuthorsData.Description}}</p>
                </div>
           </div>
        </div>
    </div>
</div> -->




   
   
    
      
            <!-- mobile view slider article -->
            <div class="row" *ngIf='resDataLoaded==false && count> 0'>
                <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
                    <list-content-loader></list-content-loader>
                </div>
            </div>
    
        <div class="row d-none d-md-flex" *ngIf="resDataLoaded">
            <div class="col-md-6 pt-1" *ngFor="let resData of resDatas| paginate : {
                itemsPerPage: pageSize,
                currentPage: page,
                totalItems: count
              };
        let i = index
        " [class.active]="i == currentIndex">
                <!-- <div class="cursor-p" routerLink='/resources/{{resData.ResourceType +"s" | lowercase }}/{{resData.RouteURL}}/{{resData.ID}}'> -->
                    <a target="_blank" routerLink='/guest-contributors/{{resData.RouteURL}}' > 
                        <img class="img-fluid pb-2 image-hight-events" [src]='resData.ImageURL' alt="news image">
                        <p class="para-2 mb-1">{{resData.Tag}}</p>
                        <p class="para-2 mb-1">{{resData.AuthorName}} | {{resData.EntryDate}}</p>
                        <h4 class="text-height-2 card-header-height-2 text-uppercase">{{resData.WhitePaperTitle}}</h4>
                        <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3">{{resData.Description}}</p>
                       </a> 
                   
                <!-- </div> -->
            </div>
           
                <div class="col-12 p-0 text-center" *ngIf="count> 7">
                    <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                </div>
           
   </div>        