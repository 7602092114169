import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  data: any;
  HomeMetaTagLoaded: boolean;
  HomeMetaTagData: any;
  envSiteName:any;

  constructor(private spinner: NgxSpinnerService,private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta)
   {this.envSiteName=environment.siteName; }

   ngOnInit(): void {
    this.AllMetaTagHome();
    // this.spinner.show();
    // setTimeout(() => {
     
    //   this.spinner.hide();
    // }, 4000);
    
  }
  AllMetaTagHome(): void {
    if(this.HomeMetaTagLoaded!=true){
    this.HomeMetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Home')
    .subscribe(
      items => {
        this.HomeMetaTagData = items[0];
        this.HomeMetaTagLoaded=true;
        console.log('this.HomeMetaTagData',this.HomeMetaTagData);


          //seo code for tags
          this.titleService.setTitle(this.HomeMetaTagData.PageTitle);

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.HomeMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'og:HeaderText', content: this.HomeMetaTagData.HeaderText}
            );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.HomeMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.HomeMetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.HomeMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.HomeMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.HomeMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.HomeMetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }

}
