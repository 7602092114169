import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-home-events',
  templateUrl: './home-events.component.html',
  styleUrls: ['./home-events.component.scss']
})
export class HomeEventsComponent implements OnInit {

  loadedUpcomingConf:boolean;
  upcomingConfDatas:any;
  loadedPastConf:boolean=false;
  pastConfDatas:any;
  loadedLiveWebiner:boolean=false;
  liveWebinarConfDatas:any;
  loadedOnDemanadWebiner:boolean=false;
  onDemanadWebinerDatas:any;
  firstString:string;
  envSiteName:any;
  


  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slideConfigUpcomingConf = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
  slideConfigPastConf = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
  slideConfigLiveWebiner = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
  slideConfigDemandWebiner = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };

  constructor(private httpclientService: HttpclientService,private routeUrl: Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.upcomingConfDataFunction();
    this.liveWebinarDataFunction();
  }

  upcomingConfDataFunction(): void {
    this.loadedUpcomingConf = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetHomeEvent?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.upcomingConfDatas = items;
          this.loadedUpcomingConf = true;
          console.log("home upcomingConfDatas",this.upcomingConfDatas)
        });
  }
  pastConfDataFunction(): void {
    if(this.loadedPastConf == false){
    this.loadedPastConf = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetHomePastEvent?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.pastConfDatas = items;
          this.loadedPastConf = true;
          console.log("home pastConfDatas",this.pastConfDatas)
        });
      }
  }

  redirectLiveWebinar(getUrl:any,getId:any)
  {
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https")
    {
      window.open(getUrl, "_blank");
    }else
    {
      this.routeUrl.navigate(['/events/live-webinars/'+getUrl+"/"+getId]);
    }
  }

  liveWebinarDataFunction(): void {
    this.loadedLiveWebiner = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetHomeLiveWebinar?siteName='+this.envSiteName)

      .subscribe(
        items => {
          this.liveWebinarConfDatas = items;
          this.loadedLiveWebiner = true;
         console.log("home liveWebinarConfDatas",this.liveWebinarConfDatas)
        });
  }

  



  // redirect(getUrl:any,getId:any)
  // {
  //   this.firstString = getUrl.substring(0,5);
  //   if(this.firstString ==="https")
  //   {
  //     window.open(getUrl, "_blank");
  //   }else
  //   {
  //     this.routeUrl.navigate(['/events/on-demand-webinars/'+getUrl+"/"+getId]);
  //   }
  // }

  onDemandWebinarDataFunction(): void {
    if(this.loadedOnDemanadWebiner == false){
    this.loadedOnDemanadWebiner = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetHomeOnDemandWebinar?siteName='+this.envSiteName)

      .subscribe(
        items => {
          this.onDemanadWebinerDatas = items;
          this.loadedOnDemanadWebiner = true;
          console.log("home onDemanadWebinerDatas",this.onDemanadWebinerDatas)
        });
      }
  }
  

}
