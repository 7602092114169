<h2 class="text-uppercase mt-3 mb-0">
    events</h2>
<div class="row" *ngIf="loaded">
    <!-- <div class="col-md-12 pt-3 cursor-p" routerLink='/events/upcoming-conferences/{{eventDatas.RouteURL}}/{{eventDatas.EventID}}'> -->
        <a target="_blank" routerLink='/{{eventDatas.RouteURL}}' >
        <img class="resources-img-h pb-2 pr-5" [src]='eventDatas.ImageUrl' alt="news image">
        <!-- <p class="para-2 mb-0 text-uppercase">CORE re</p> -->
        <h4 class="text-uppercase">{{eventDatas.Name}}</h4>
    </a>
        <!-- <p class="para-3 text-capitalize">{{eventDatas.ResourceType}}</p> -->
        <p class="para-3 text-capitalize">Conference</p>
    <!-- </div> -->
</div>