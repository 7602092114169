import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-global-news',
  templateUrl: './global-news.component.html',
  styleUrls: ['./global-news.component.scss']
})
export class GlobalNewsComponent implements OnInit {

  featureNewsDatas: any;
  featureLoaded: boolean;
  trendingNewsDatas: any;
  
  trendingLoaded: boolean;
  archivedLoaded: boolean;
  archivedNewsDatas: any;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }
  ngOnInit(): void {
    this.featuredNews();
    this.trendingNews();
    this.archivedNews();
  }

  featuredNews(): void {
    this.featureLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/News/GetTop9FutureNewsDetails?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.featureNewsDatas = items;
          this.featureLoaded = true;
          console.log("news featureNewsDatas",this.featureNewsDatas)
        });
  }
  trendingNews(): void {
    this.trendingLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/News/GetTop9TrendingNewsDetails?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.trendingNewsDatas = items;
          this.trendingLoaded = true;
          console.log("news trendingNewsDatas",this.trendingNewsDatas)
        });
  }
  archivedNews(): void{
    this.archivedLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/News/GetTop9ArchivedNewsDetails?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.archivedNewsDatas = items;
        this.archivedLoaded = true;
        console.log("News ArchivedNews Data",this.archivedNewsDatas);
      }
    )
  }

}
