<h1 class="text-uppercase mb-0 mt-3">News</h1>
<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
        <a class="nav-link active pl-4 pr-4 pl-md-5 pr-md-5 border-right-none" id="home-tab" data-toggle="tab" href="#news-tab1" role="tab" aria-controls="home" aria-selected="true">FEATURED NEWS</a>
    </li>
    <li class="nav-item">
        <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 border-right-none" id="profile-tab" data-toggle="tab" href="#news-tab2" role="tab" aria-controls="profile" aria-selected="false">TRENDING NEWS</a>
    </li>
    <!-- <li class="nav-item">
        <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5" id="profile-tab" data-toggle="tab" href="#news-tab3" role="tab" aria-controls="profile" aria-selected="false">ARCHIVED NEWS</a>
    </li> -->

</ul>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active pt-2" id="news-tab1" role="tabpanel" aria-labelledby="home-tab">
        <!-- mobile view slider -->
        <!-- <div class="col-12 d-block d-md-none pt-3 p-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigFeatureNews" *ngIf="featureLoaded">
                <div ngxSlickItem *ngFor="let featureNewsData of featureNewsDatas" class="slide">
                    <img class="img-fluid pb-2 image-hight-news" [src]="featureNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0">CORE re</p>
                    <h4 class="text-height-4 card-header-height-1" [innerHTML]="featureNewsData.Title">
                    </h4>
                    <p class="para-2 mb-1">True Influence | {{featureNewsData.Date}} </p>
                    <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="featureNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 " href="">Read More</a>
                </div>
            </ngx-slick-carousel>
        </div> -->
        <!-- mobile view slider end -->
        <div class="row d-md-flex" *ngIf="featureLoaded">
            <div class="col-md-4 pt-3" *ngFor="let featureNewsData of featureNewsDatas">
                <!-- <div class="cursor-p" routerLink='{{featureNewsData.EventDifferentType}}/{{featureNewsData.RouteURL}}'> -->
                    <a target="_blank" href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType | lowercase }}/{{featureNewsData.RouteURL}}'> 
                    <img class="img-fluid pb-2 image-hight-news" [src]="featureNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0">{{featureNewsData.Tag}}</p>
                    <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="featureNewsData.Title">
                    </h4>
                </a>
                    <p class="para-2 mb-1">{{featureNewsData.CompanyName}} | {{featureNewsData.Date}} </p>
                    <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="featureNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 " target="_blank" href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType | lowercase }}/{{featureNewsData.RouteURL}}'>Read More</a>
                <!-- </div> -->
            </div>
        </div>
        <div class="text-center">
            <button class="text-uppercase thm-btn mt-4 btn-theme-success w-75 pt-3 pb-3" routerLink="featured-news">more featured news</button>
        </div>
    </div>
    <div class="tab-pane pt-2 fade" id="news-tab2" role="tabpanel" aria-labelledby="profile-tab">
        <!-- mobile view slider -->
        <!-- <div class="col-12 d-block d-md-none pt-3 p-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigTrendingNews" *ngIf="trendingLoaded">
                <div ngxSlickItem *ngFor="let trendingNewsData of trendingNewsDatas" class="slide">
                    <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0">CORE re</p>
                    <h4 class="text-height-4 card-header-height-1">{{trendingNewsData.Title}}
                    </h4>
                    <p class="para-2 mb-1">True Influence | {{trendingNewsData.Date}} </p>
                    <p class="para-1 text-justify mb-1 text-height-4">{{trendingNewsData.Description}}</p>
                    <a class="para-2 para-2-c-2 " href="">Read More</a>
                </div>
            </ngx-slick-carousel>
        </div> -->
        <!-- mobile view slider end -->
        <div class="row d-md-flex" *ngIf="trendingLoaded">
            <div class="col-md-4 pt-3" *ngFor="let trendingNewsData of trendingNewsDatas">
                <!-- <div class="cursor-p" routerLink='{{trendingNewsData.EventDifferentType}}/{{trendingNewsData.RouteURL}}/{{trendingNewsData.ID}}'> -->
                  <a target="_blank" href='https://{{envSiteName}}/{{trendingNewsData.EventDifferentType | lowercase }}/{{trendingNewsData.RouteURL}}'> 
                    <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0">{{trendingNewsData.Tag}}</p>
                    <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="trendingNewsData.Title">
                    </h4>
                </a>
                    <p class="para-2 mb-1">{{trendingNewsData.CompanyName}} | {{trendingNewsData.Date}} </p>
                    <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="trendingNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 " target="_blank" href='https://{{envSiteName}}/{{trendingNewsData.EventDifferentType | lowercase }}/{{trendingNewsData.RouteURL}}'>Read More</a>
                <!-- </div> -->
            </div>
        </div>
        <div class="text-center">
            <button class="text-uppercase thm-btn mt-4 btn-theme-success w-75 pt-3 pb-3" routerLink="trending-news">more trending news</button>
        </div>
    </div>
    <div class="tab-pane pt-2 fade" id="news-tab3" role="tabpanel" aria-labelledby="profile-tab">
        <!-- mobile view slider -->
        <!-- <div class="col-12 d-block d-md-none pt-3 p-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigTrendingNews" *ngIf="trendingLoaded">
                <div ngxSlickItem *ngFor="let trendingNewsData of trendingNewsDatas" class="slide">
                    <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0">CORE re</p>
                    <h4 class="text-height-4 card-header-height-1">{{trendingNewsData.Title}}
                    </h4>
                    <p class="para-2 mb-1">True Influence | {{trendingNewsData.Date}} </p>
                    <p class="para-1 text-justify mb-1 text-height-4">{{trendingNewsData.Description}}</p>
                    <a class="para-2 para-2-c-2 " href="">Read More</a>
                </div>
            </ngx-slick-carousel>
        </div> -->
        <!-- mobile view slider end -->
        <div class="row d-md-flex" *ngIf="trendingLoaded">
            <div class="col-md-4 pt-3" *ngFor="let archivedNewsData of archivedNewsDatas">
                <!-- <div class="cursor-p" routerLink='archive-news/{{archivedNewsData.RouteURL}}/{{archivedNewsData.ID}}'> -->
                    <a target="_blank"  routerLink='archive-news/{{archivedNewsData.RouteURL}}/{{archivedNewsData.ID}}'>
                    <img class="img-fluid pb-2 image-hight-news" [src]="archivedNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0">{{archivedNewsData.Tag}}</p>
                    <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="archivedNewsData.Title">
                         </h4>
                        </a>
                    <p class="para-2 mb-1">{{archivedNewsData.CompanyName}} | {{archivedNewsData.Date}} </p>
                    <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="archivedNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 " href="">Read More</a>
                <!-- </div> -->
            </div>
        </div>
        <div class="text-center">
            <button class="text-uppercase thm-btn mt-4 btn-theme-success w-75 pt-3 pb-3">more archive news</button>
        </div>
    </div>
</div>