import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-home-news',
  templateUrl: './home-news.component.html',
  styleUrls: ['./home-news.component.scss']
})
export class HomeNewsComponent implements OnInit {

  featureNewsDatas: any;
  featureLoaded: boolean;
  trendingNewsDatas: any;
  trendingLoaded: boolean;
  envSiteName:any;

  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  slideConfigFeatureNews = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
 
  slideConfigTrendingNews = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
 

  constructor(private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.featuredNews();
    // this.trendingNews();
  }

  featuredNews(): void {
    if(this.featureLoaded!=true){
    this.featureLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetTop6FutureNewsDetails?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.featureNewsDatas = items;
          this.featureLoaded = true;
          console.log("news featureNewsDatas",this.featureNewsDatas)
        });
      }
  }
  trendingNews(): void {
    if(this.trendingLoaded!=true){
    this.trendingLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetTop6TrendingNewsDetails?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.trendingNewsDatas = items;
          this.trendingLoaded = true;
          console.log("news trendingNewsDatas",this.trendingNewsDatas)
        });
      }
  }


}
