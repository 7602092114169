
<h2 class="text-uppercase mb-0 mt-2">
    C-Suite On Deck</h2>

    <div class="mt-3" *ngIf="cSuiteOnDeckloaded">
        <div class="mb-5" *ngFor="let cSuiteOnDeckData of cSuiteOnDeckDatas">
            <a class="cursor-p" [href]='cSuiteOnDeckData.RouteURL' target="_blank">
         
            <!-- <div class="cursor-p" routerLink='{{cSuiteOnDeckData.RouteURL}}'> -->
            <img [src]="cSuiteOnDeckData.InterviewImage" class="img-fluid" alt="Responsive image">
            <h4 class="text-uppercase mt-2">{{cSuiteOnDeckData.InterviewTitle}}</h4>
            </a>
            <p class="para-2 mb-1">{{cSuiteOnDeckData.Name}} | {{cSuiteOnDeckData.InterviewDate}} </p>
            <p class="text-justify para-1 text-height-4 mb-1" [innerHTML]=" cSuiteOnDeckData.InterviewDetails"></p>
            <a class="para-2 para-2-c-2 cursor-p" [href]='cSuiteOnDeckData.RouteURL' target="_blank">Read More</a>
        </div>
    </div>

