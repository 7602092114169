import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-spotlight-news',
  templateUrl: './spotlight-news.component.html',
  styleUrls: ['./spotlight-news.component.scss']
})
export class SpotlightNewsComponent implements OnInit {
  iData: any;
  spotLightDatas: any;
  loaded: boolean;
  companyDomain: string;
  envSiteName:any;

  constructor(private httpclientService: HttpclientService) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.interviewData();
  }
  interviewData(): void {
    this.loaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetHomeInterview?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.iData = items[0];
          this.loaded = true;
          console.log("home",this.iData);
          this.companyDomain=this.iData.CompanyDomain;
          this.spotLightData();
        });
  }
  spotLightData(): void
  {
    this.loaded =false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetNewsSpotLight?siteName='+this.envSiteName+'&domainName='+this.companyDomain)
    .subscribe(
      items => {
        this.spotLightDatas = items[0];
        this.loaded = true;
        console.log("SpotLight News Data:", this.spotLightDatas,this.companyDomain);
       // console.log("domain name:" ,this.companyDomain);
      }
    );
  }

}
