<h2 class="text-uppercase mt-3 mb-0">
    resources</h2>
<div class="row" *ngIf="loaded">
    <!-- <div class="col-md-12 pt-3 cursor-p" routerLink='/resources/{{resDatas.ResourceType +"s" | lowercase }}/{{resDatas.RouteURL}}/{{resDatas.ID}}'> -->
      <a target="_blank"  routerLink='{{resDatas.RouteURL}}' >
        <img class="resources-img-h pb-2 pr-5" [src]='resDatas.ImageUrl' alt="news image">
        <!-- <p class="para-2 mb-0 text-uppercase">CORE re</p> -->
        <h4 class="text-uppercase">{{resDatas.WhitePaperTitle}}</h4>
        <p class="para-3 text-capitalize">{{resDatas.ResourceType}}</p>
    </a>
    <!-- </div> -->
</div>