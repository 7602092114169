import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { environment } from '../../../../../../../src/environments/environment';

const STATE_KEY_ITEMS = makeStateKey('items');


@Component({
  selector: 'app-past-conferences',
  templateUrl: './past-conferences.component.html',
  styleUrls: ['./past-conferences.component.scss']
})
export class PastConferencesComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 9;

  PastConferenceDatas:any;  
  pastConferenceLoaded:boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;

  constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.AllPastConferences();
   
  }

  AllPastConferences(): void {
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
   
    this.pastConferenceLoaded = false;
     this.httpclientService.getHttpclient('https://api.nathanark.com/api/GlobalEvents/GetGlobalEventAllPastConferences?siteName='+this.envSiteName+'&pageNumber='+this.page)
      .subscribe(
        items => {
          const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
          console.log(`getUsers : Running ${platform} with appId=${this.appId}`);
          this.PastConferenceDatas = items;
          // this.ConferenceLoaded = true;

          this.count=this.PastConferenceDatas[0].TotalDataCount;
          console.log("data count:",this.count);
          this.pastConferenceLoaded = true;
          console.log("PastConferences",this.PastConferenceDatas)
          this.state.set(STATE_KEY_ITEMS, <any> items);
        });
     
  }

  

  resetUsers(): void {
    this.PastConferenceDatas = null;
    this.pastConferenceLoaded = true;

  }
  handlePageChange(event): void {
    this.page = event;
    console.log("this.page",this.page);
    this.AllPastConferences();
    this.onActivate();
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}


}
