<h2 class="text-uppercase mt-3 mb-0">
    Events</h2>
<div class="row" *ngIf="loaded">
    <div class="col-md-12 pt-3" *ngFor="let eventData of eventDatas">
        
        <!-- <div class="cursor-p" routerLink='/events/upcoming-conferences/{{eventData.RouteURL}}/{{eventData.EventID}}'> -->
            <a target="_blank" href='{{eventData.RouteURL}}' > 
            <img class="resources-img-h pb-2 pr-5" [src]='eventData.ImageUrl' alt="news image">
            <h4 class="text-uppercase">{{eventData.Name}}</h4>
            <p class="para-3 text-capitalize">Conference</p>
            </a>
        <!-- </div> -->
   </div>
</div>
