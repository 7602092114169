<div class="row">
    <div class="col-md-9">
        <h1 class="text-uppercase mb-0 mt-2">Resources</h1>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase border-right-none" id="home-tab" data-toggle="tab" routerLink="/resources/articles" role="tab" aria-controls="home" aria-selected="false">Articles</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase border-right-none" id="profile-tab" data-toggle="tab" routerLink="/resources/videos" role="tab" aria-controls="profile" aria-selected="false">Videos</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase border-right-none" id="profile-tab" data-toggle="tab" routerLink="/resources/whitepapers" role="tab" aria-controls="profile" aria-selected="false">Whitepapers</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase border-right-none" id="profile-tab" data-toggle="tab" routerLink="/resources/infographics" role="tab" aria-controls="profile" aria-selected="false">Infographics</a>
            </li>
            <li class="nav-item">
                <a class="nav-link pl-4 pr-4 pl-md-5 pr-md-5 text-uppercase active" id="profile-tab" data-toggle="tab" routerLink="/resources/webinars" role="tab" aria-controls="profile" aria-selected="false">Webinars</a>
            </li>
        </ul>
        <div class="row mt-5" *ngIf='resourcesWebinarLoaded==false'>
            <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
                <list-content-loader></list-content-loader>
            </div>
        </div>
        <div class="row d-md-flex mt-3" *ngIf="resourcesWebinarLoaded">
            <div class="col-md-4 pb-5" *ngFor=" let resourcesWebinarData of resourcesWebinarDatas | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
    let i = index
  " [class.active]="i == currentIndex">
                <!-- <div class="cursor-p" (click)="redirect(resourcesWebinarData.EventDifferentType,resourcesWebinarData.RouteURL,resourcesWebinarData.EventID)"> -->
                    <a [href]="resourcesWebinarData.RouteURL" target="_blank">
                    <!-- <a target="_blank" routerLink="/{{resourcesWebinarData.RouteURL}}/{{resourcesWebinarData.EventID}}"> -->
                    <img class="img-fluid pb-2 image-hight-news" [src]="resourcesWebinarData.ImageUrl" alt="news image">
                    <h4 class="text-height-3  text-uppercase" [innerHTML]="resourcesWebinarData.Name">

                    </h4>
                     </a> 
                    
                    <p class="para-2 mb-2">{{resourcesWebinarData.Keywords}} | Sponsored
                        <!-- <span *ngIf="resourcesWebinarData.IsSponsored"><a> | </a> <font class="para-2-c-2">Sponsored</font></span> -->
                    </p>

                    <!-- <p class="para-2 mb-1">{{resourcesWebinarData.DateWithStartTime}} </p> -->
                    <p class="para-1 text-justify mb-1 text-height-4 card-header-height-4" [innerHTML]="resourcesWebinarData.Details"></p>
                   
                    <a class="para-2 para-2-c-2 " [href]="resourcesWebinarData.RouteURL" target="_blank">Watch Now</a>
                   
                <!-- </div> -->
            </div>
        </div>

        <div class="col-12 p-0 text-center" *ngIf="count>9">
            <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
        </div>
    </div>
    <div class="col-md-3">
        <app-resources-spotlight></app-resources-spotlight>
        <app-square-ad-videos></app-square-ad-videos>
        <app-resources-event></app-resources-event>
        <app-square-ad-videos></app-square-ad-videos>
    </div>
</div>