<div class="row" *ngIf='LiveWebinarLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex mt-3" *ngIf="LiveWebinarLoaded | paginate : {
    itemsPerPage: pageSize,
    currentPage: page,
    totalItems: count
  };
let i = index
" [class.active]="i == currentIndex">
    <div class="col-md-4 pb-5" *ngFor="let livewebinarNewsData of LiveWebinarDatas">

        <!-- <div class="cursor-p" (click)="redirect(livewebinarNewsData.RouteURL,livewebinarNewsData.EventID)"> -->
        <a target="_blank" href="live-webinars/{{livewebinarNewsData.RouteURL}}">
            <img class="img-fluid pb-2 image-hight-news" [src]="livewebinarNewsData.ImageUrl" alt="news image">
            <h4 class="text-height-3 card-header-height-3 text-uppercase" [innerHTML]="livewebinarNewsData.Name">
            </h4>
        </a>
        <p class="para-2 mb-1"> {{livewebinarNewsData.DateWithStartTime}} | {{livewebinarNewsData.Country}}</p>
        <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="livewebinarNewsData.Details"></p>
        <a target="_blank" href="live-webinars/{{livewebinarNewsData.RouteURL}}">
           Register Now
            </a>
        <!-- <a class="para-2 para-2-c-2 " href="events/live-webinars/{{livewebinarNewsData.RouteURL}}/{{livewebinarNewsData.EventID}} ">Register Now</a> -->


        <!-- </div> -->
    </div>
</div>
<div class="row">
    <div class="col-12 p-0 text-center" *ngIf="count>9">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>