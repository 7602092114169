<div class="row" *ngIf='OnDemandWebinarLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex mt-3" *ngIf="OnDemandWebinarLoaded">
    <div class="col-md-4 pb-5" *ngFor="let ondemandwebinarData of OnDemandWebinarDatas | paginate : {
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count
      };
    let i = index
    " [class.active]="i == currentIndex" >
    <div class="cursor-p">
        <!-- <a target="_blank" routerLink="" > -->
           
            <a [href]="ondemandwebinarData.RouteURL" target="_blank">
            <img class="img-fluid pb-2 image-hight-news" [src]="ondemandwebinarData.ImageUrl" alt="news image">
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="ondemandwebinarData.Name">
            </h4>
     </a> 
            <p class="para-2 mb-2">{{ondemandwebinarData.Keywords}} |Sponsored
            </p>
            <p class="para-1 text-justify mb-1 text-height-4 card-header-height-4" [innerHTML]="ondemandwebinarData.Details"></p>
            <a  [href]="ondemandwebinarData.RouteURL">
            <a class="para-2 para-2-c-2" target="_blank" [href]="ondemandwebinarData.RouteURL">Watch Now</a>
            </a>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 p-0 text-center" *ngIf="count>9">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>