<p class="mb-0 para-2-c-2">
    <a routerLink="/">Home</a> >
     <a routerLink="/news">News</a> >
      <a  class="para-2-c-1">Featured News</a>
      
</p>
<h2 class="text-uppercase mb-3">Featured News</h2>
<div class="row" *ngIf='featureLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex" *ngIf="featureLoaded">
    <div class="col-md-4 pb-5" *ngFor="
    let featureNewsData of featureNewsDatas | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
          };
    let i = index
  " [class.active]="i == currentIndex">
        <!-- <div class="cursor-p" routerLink='{{featureNewsData.RouteURL}}/{{featureNewsData.ID}}'> -->
            <!-- <a routerLink='{{featureNewsData.RouteURL}}/{{featureNewsData.ID}}' target="_blank"> -->
            <a href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType  | lowercase }}/{{featureNewsData.RouteURL}}' target="_blank">

            <img class="img-fluid pb-2 image-hight-news" [src]="featureNewsData.ImageUrl" alt="news image">
            <p class="para-2 mb-0"> {{featureNewsData.Tag}}</p>
            <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="featureNewsData.Title">

            </h4>
        </a>
            <p class="para-2 mb-1">{{featureNewsData.CompanyName}} | {{featureNewsData.Date}} </p>
            <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="featureNewsData.Description"></p>
            <!-- <a class="para-2 para-2-c-2 " target="_blank" href='news/featured-news/{{featureNewsData.RouteURL}}/{{featureNewsData.ID}}'>Read More</a> -->
            <a class="para-2 para-2-c-2 " target="_blank" href='https://{{envSiteName}}/{{featureNewsData.EventDifferentType  | lowercase }}/{{featureNewsData.RouteURL}}'>Read More</a>
        <!-- </div> -->
    </div>
</div>

<div class="row">
    <div class="col-12 p-0 text-center" *ngIf="count>9">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>