<h2 class="text-uppercase text-center mb-0 mt-2">
    Spotlight</h2>
<div class="text-center" *ngIf="loaded">
    <div class="cursor-p">
        <!-- (click)="redirect(spotLightDatas.RouteURL)" -->
        <!-- <div class="cursor-p" routerLink='{{spotLightDatas.RouteURL}}'> -->
        <a target="_blank" href="{{spotLightDatas.RouteURL}}">
            <img [src]=" spotLightDatas.ImageUrl " class="img-fluid " alt="Responsive image ">
            <h4 class="text-uppercase mt-2 text-center ">{{spotLightDatas.Name}}</h4>
        </a>
    </div>
    <p class="text-justify para-1 spotlight-text-height " [innerHTML]=" spotLightDatas.Description "></p>
</div>