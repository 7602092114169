import { Component, Input, OnInit } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from 'src/environments/environment';
const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-resources-guest-authors',
  templateUrl: './resources-guest-authors.component.html',
  styleUrls: ['./resources-guest-authors.component.scss']
})
export class ResourcesGuestAuthorsComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 8;
  resDatas: any;
  resDataLoaded: boolean;
  envSiteName:any;
  scrollToTop:any;
  pos:any;
  @Input() AuthorEmail: any;
  constructor(private state: TransferState,private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.interviewData();
  }
  interviewData(): void {
    this.count = 0;
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.resDataLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetAllHomeResourcesByAuthor?siteName='+this.envSiteName+'&pageNumber='+ this.page+'&email='+this.AuthorEmail)
      .subscribe(
        items => {
         
            this.resDatas = items;
            console.log("resDatas:",this.resDatas);
            this.count=this.resDatas[0].TotalDataCount;
            console.log("Count AAAnkjsjghddq::::", this.count);
            this.resDataLoaded = true;
            this.state.set(STATE_KEY_ITEMS, <any> items);
         
          
        });
  }
  resetUsers(): void {
    this.count = 0;
    this.resDatas = null;
    this.resDataLoaded = true;
  }
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.interviewData();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
}
