import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { strict } from 'assert';
import { Router } from '@angular/router';
import { environment } from '../../../../../../src/environments/environment';

const STATE_KEY_ITEMS = makeStateKey('items');


@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  charsArrays:any=['A','B','C','D','E','F','G','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';

  char: '';
  page = 1;
  count = 0;
  pageSize = 9;

  href:string;
  lastString:string;
  companiesDatas: any;
  companyLoaded: boolean;
  searchcompaniesDatas:any;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  
  constructor(private state: TransferState,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,public router:Router) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.companies();
    
  }


  companies(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1);

    
    this.companyLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/GlobalCompanies/GetAllGlobalCompanies?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        this.companiesDatas = items;
        this.count = this.companiesDatas[0].TotalDataCount;
        this.companyLoaded = true;
        console.log("Companies Data:", this.companiesDatas);
      });
  }

  GetAllGlobalCompaniesSerach(newChar: any): void{
    this.companyLoaded = false;
    this.count = 0;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/GlobalCompanies/GetAllGlobalCompaniesForSearch?siteName='+this.envSiteName+'&SearchChar='+newChar+'&pageNumber='+this.page)
    .subscribe(
      items => {
        const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
          this.companiesDatas = items;
          this.count = this.companiesDatas[0].TotalDataCount;
          this.companyLoaded = true;
          console.log("Search GlobalCompanies",this.companiesDatas)
          this.state.set(STATE_KEY_ITEMS, <any> items);
      });
  }

  handlePageChangeForSearch(charData):void{
    this.char  = '';
    this.page =1;
    this.char = charData;
    this.resetUsers();
    this.GetAllGlobalCompaniesSerach(this.char);
    
  }

  resetUsers(): void {
    this.companiesDatas = null;
    this.companyLoaded = true;

  }
  
  handlePageChange(event): void {
    this.resetUsers();
      this.page = event;
      if(!this.char){
        this.companies();
      }
     else
     {
      this.GetAllGlobalCompaniesSerach(this.char);
      this.companiesDatas;
     }
      
      this.onActivate();
  }

  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}

}
