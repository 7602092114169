<h2 class="text-uppercase mt-4 mb-0">News</h2>
<ul class="nav nav-tabs nav-tabs-width mb-3" id="myTab" role="tablist">
    <li class="nav-item">
        <a class="nav-link text-center active" id="home-tab" data-toggle="tab" href="#news-tab1" role="tab" aria-controls="home" aria-selected="true">FEATURED</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-center" id="profile-tab" data-toggle="tab" href="#news-tab2" (click)="trendingNews()" role="tab" aria-controls="profile" aria-selected="false">TRENDING</a>
    </li>

</ul>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="news-tab1" role="tabpanel" aria-labelledby="home-tab">
        <!-- mobile view slider -->
        <div class="col-12 d-block d-md-none pt-3 p-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigFeatureNews" *ngIf="featureLoaded">
                <div ngxSlickItem *ngFor="let featureNewsData of featureNewsDatas" class="slide">
                    <div class="cursor-p" routerLink='featured-news/{{featureNewsData.RouteURL}}' target="_blank">               
                        <img class="img-fluid pb-2 image-hight-news" [src]="featureNewsData.ImageUrl" alt="news image">
                    </div>
                        <a  routerLink='featured-news/{{featureNewsData.RouteURL}}'>
                        <p class="para-2 mb-0">{{featureNewsData.Tag}}</p>
                   
                        <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="featureNewsData.Title">
                        </h4>
                    </a>
                    <p class="para-2 mb-2">{{featureNewsData.CompanyName}} | {{featureNewsData.Date}}

                        <!-- <span  *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
                    </p>
                    <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="featureNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 " target="_blank" routerLink='featured-news/{{featureNewsData.RouteURL}}'>Read More</a>
                
            </div>
            </ngx-slick-carousel>
            <div class="mt-2 mb-4">
                <app-square-ad></app-square-ad>
            </div>
        </div>
        <!-- mobile view slider end -->
        <div class="row d-none d-md-flex" *ngIf="featureLoaded">
            <div class="col-md-4 mb-5" *ngFor="let featureNewsData of featureNewsDatas">
                <!-- <div class="cursor-p" routerLink='/news/featured-news/{{featureNewsData.RouteURL}}/{{featureNewsData.ID}}'> -->
                 <a   target="_blank" routerLink='featured-news/{{featureNewsData.RouteURL}}'> 
                    <img class="img-fluid pb-2 image-hight-news " [src]="featureNewsData.ImageUrl" alt="news image">
                   
                    <p class="para-2 mb-0">{{featureNewsData.Tag}}</p>
               
                    <h4 class="text-height-4 card-header-height-1 text-uppercase" [innerHTML]="featureNewsData.Title">
                    </h4>
                 </a> 
            <!-- </div> -->
                    <p class="para-2 mb-2">{{featureNewsData.CompanyName}} | {{featureNewsData.Date}}

                        <!-- <span  *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
                    </p>
                    <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="featureNewsData.Description"></p>
                    <a class="para-2 para-2-c-2 " target="_blank" routerLink='featured-news/{{featureNewsData.RouteURL}}'>Read More</a>
               
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="news-tab2" role="tabpanel" aria-labelledby="profile-tab">
        <!-- mobile view slider -->
        <div class="col-12 d-block d-md-none pt-3 p-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigTrendingNews" *ngIf="trendingLoaded">
                <div ngxSlickItem *ngFor="let trendingNewsData of trendingNewsDatas" class="slide">
                    <!-- <div class="cursor-p" routerLink='/news/trending-news/{{trendingNewsData.RouteURL}}/{{trendingNewsData.ID}}'> -->
                    <a target="_blank" routerLink='trending-news/{{trendingNewsData.RouteURL}}'>
                        <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0">{{trendingNewsData.Tag}}</p>
                    
                        <h4 class="text-height-4 card-header-height-1 text-uppercase">{{trendingNewsData.Title}}
                        </h4>
                    </a>
                    <p class="para-2 mb-2">{{trendingNewsData.CompanyName}} | {{trendingNewsData.Date}}

                        <!-- <span  *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
                    </p>
                    <p class="para-1 text-justify mb-1 text-height-4">{{trendingNewsData.Description}}</p>
                    <a class="para-2 para-2-c-2 " target="_blank" routerLink='trending-news/{{trendingNewsData.RouteURL}}'>Read More</a>
                <!-- </div> -->
            </div>
            </ngx-slick-carousel>
        </div>
        <!-- mobile view slider end -->
        <div class="row d-none d-md-flex" *ngIf="trendingLoaded">
            <div class="col-md-4 mb-5" *ngFor="let trendingNewsData of trendingNewsDatas">
                <!-- <div class="cursor-p" routerLink='/news/trending-news/{{trendingNewsData.RouteURL}}/{{trendingNewsData.ID}}'> -->
                <a target="_blank" routerLink='trending-news/{{trendingNewsData.RouteURL}}'>
                    <img class="img-fluid pb-2 image-hight-news" [src]="trendingNewsData.ImageUrl" alt="news image">
                    <p class="para-2 mb-0">{{trendingNewsData.Tag}}</p>

                    <h4 class="text-height-4 card-header-height-1 text-uppercase">{{trendingNewsData.Title}}
                    </h4>
                </a>
                    <p class="para-2 mb-2">{{trendingNewsData.CompanyName}} | {{trendingNewsData.Date}}
                        <!-- <span *ngIf="trendingNewsData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>     -->
                    </p>
                    <p class="para-1 text-justify mb-1 text-height-4">{{trendingNewsData.Description}}</p>
                    <a class="para-2 para-2-c-2" target="_blank" routerLink='trending-news/{{trendingNewsData.RouteURL}}'>Read More</a>
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>