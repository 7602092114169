<div class="row" *ngIf='ConferenceLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex" *ngIf="ConferenceLoaded">
    <div class="col-md-4 pb-5" *ngFor="let UpcomingConferences of UpcomingConferenceDatas | paginate : {
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count
      };
let i = index
" [class.active]="i == currentIndex">
        <!-- <div class="cursor-p" routerLink="{{UpcomingConferences.RouteURL}}/{{UpcomingConferences.EventID}}">  -->
            <!-- <div class="cursor-p" routerLink="{{UpcomingConferences.URL}}"> -->
                <!-- <a  routerLink="{{UpcomingConferences.RouteURL}}/{{UpcomingConferences.EventID}}" target="_blank" > -->
            <a href='https://{{envSiteName}}/{{UpcomingConferences.EventDifferentType | lowercase }}/{{UpcomingConferences.RouteURL}}' target="_blank" >       
            <img class="img-fluid pb-2 image-hight-news " [src]="UpcomingConferences.ImageUrl" alt="news image">
            <h4 class="text-height-3 card-header-height-3 text-uppercase" [innerHTML]="UpcomingConferences.Name">
            </h4>
              </a>
            <p class="para-2 mb-1"> {{UpcomingConferences.DateWithStartTime}} | {{UpcomingConferences.Country | firstWord}}</p>
            <p class="para-1 text-justify mb-1 text-height-4" [innerHTML]="UpcomingConferences.Details"></p>
         <!-- <a class="para-2 para-2-c-2 " target="_blank" routerLink="{{UpcomingConferences.RouteURL}}/{{UpcomingConferences.EventID}}">Register Now</a>  -->
         <a class="para-2 para-2-c-2 " target="_blank" href='https://{{envSiteName}}/{{UpcomingConferences.EventDifferentType | lowercase }}/{{UpcomingConferences.RouteURL}}'>Register Now</a> 

            <!-- <a class="para-2 para-2-c-2 " href="events/upcoming-conferences/{{UpcomingConferences.RouteURL}}/{{UpcomingConferences.EventID}}" target="_blank">Register Now</a> -->
        <!-- </div> -->
    </div>
</div>
<div class="row">
    <div class="col-12 p-0 text-center" *ngIf="count>9">
        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>