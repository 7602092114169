<h2 class="text-uppercase text-center mb-0 mt-4">
    Spotlight</h2>
<div class="text-center" *ngIf="TopSpotlightLoaded" >
    <div class="pt-3" *ngFor="let topspotlightData of TopSpotlightDatas">

        <div class="cursor-p">

            <a href="{{topspotlightData.URL}}" target="_blank">

            <img [src]="topspotlightData.ImageUrl" class="img-fluid" alt="Responsive image">
            <h4 class="text-uppercase mt-2 text-center">{{topspotlightData.Name}}</h4>
        </a>
            </div>
            <p class="text-justify para-1 spotlight-text-height" [innerHTML]=" topspotlightData.Description"></p>
        </div>
    </div>
        

<!-- 

        <div class="cursor-p" routerLink='/companies/all-companies/{{topspotlightData.RouteURL}}/{{topspotlightData.EventID}}'>
     
        <img [src]="topspotlightData.ImageUrl" class="img-fluid" alt="Responsive image">
        <h4 class="text-uppercase mt-2 text-center">{{topspotlightData.Name}}</h4>
    </div>
        <p class="text-justify para-1 spotlight-text-height" [innerHTML]="topspotlightData.Description"></p>
    </div>
</div> -->