
<div class="row">
    <div class="col-sm-3">
        <h2 class="text-uppercase mb-0">Events</h2>
    </div>
    <div class="col-sm-8">
        <a href="https://eventually.com/" target="_blank"> <img src="https://abm.report/images/evn-logo.svg" class="eventually-logo"/></a>
    </div>
</div>
<!-- <h1 class="text-uppercase mb-0">Events</h1> -->
<div class="row">
    <div class="col-md-6">
        <h3>Conferences</h3>
        <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-2 pr-2 text-uppercase text-center" id="home-tab" data-toggle="tab" href="#conferances-tab1" role="tab" aria-controls="home" aria-selected="true">upcoming conferences</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-md-3 pr-md-4 text-uppercase text-center" id="profile-tab" data-toggle="tab" href="#conferances-tab2" role="tab" aria-controls="profile" aria-selected="false">past conferences</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active pt-3" id="conferances-tab1" role="tabpanel" aria-labelledby="home-tab">
                <div class="row d-md-flex fix-min-height-events" *ngIf="ConferenceLoaded">
                    <div class="col-md-12 pb-5" *ngFor="let AllUpComingConferences of ConferenceDatas">
                        <div>
                            <a target="_blank" href="upcoming-conferences/{{AllUpComingConferences.RouteURL}}" class="text-decoration-none">
                                <img class="img-fluid pb-2 image-hight-events" [src]="AllUpComingConferences.ImageUrl" alt="news image"></a>

                            <a target="_blank" href="upcoming-conferences/{{AllUpComingConferences.RouteURL}}" class="text-decoration-none">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase" [innerHTML]="AllUpComingConferences.Name"></h4>
                            </a>
                            <p class="para-2 mb-1"> {{AllUpComingConferences.DateWithStartTime}} | {{AllUpComingConferences.Country}} </p>
                            <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="AllUpComingConferences.Details"></p>
                            <!-- <a class="para-2 para-2-c-2 " href="{{AllUpComingConferences.URL}}" target="_blank">Register Now</a> -->
                            <a class="para-2 para-2-c-2 " target="_blank" href="upcoming-conferences/{{AllUpComingConferences.RouteURL}} ">Register Now</a>

                        </div>
                    </div>
                </div>
                <a target="_blank" routerLink="/events/upcoming-conferences" class="text-center text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 white-color">more upcoming conferences</a>
            </div>
            <div class="tab-pane fade pt-3" id="conferances-tab2" role="tabpanel" aria-labelledby="profile-tab">
                <!-- <div class="tab-pane fade show active pt-3" id="conferances-tab2" role="tabpanel" aria-labelledby="profile-tab"> -->
                <div class="row d-md-flex fix-min-height-events" *ngIf="EventLoaded">
                    <div class="col-md-12 pb-5" *ngFor="let AllEvents of EventNewsDatas">
                        <div>
                            <a target="_blank"  href="past-conferences/{{AllEvents.RouteURL}}" class="text-decoration-none">
                                <img class="img-fluid pb-2 image-hight-events" [src]="AllEvents.ImageUrl" alt="news image"></a>
                            <a target="_blank"  href="past-conferences/{{AllEvents.RouteURL}}" class="text-decoration-none">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase" [innerHTML]="AllEvents.Name">
                                </h4>
                            </a>
                            <p class="para-2 mb-1"> {{AllEvents.DateWithStartTime}} | {{AllEvents.Country}}

                            </p>
                            <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="AllEvents.Details"></p>
                            <a class="para-2 para-2-c-2 " href=""></a>
                        </div>
                    </div>
                </div>
                <a routerLink="/events/past-conferences" class="text-center text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 white-color">more past conferences</a>
                <!-- </div> -->
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <h3 class="mt-4 mt-md-0">Webinars</h3>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-5 pr-5 text-uppercase text-center" id="home-tab" data-toggle="tab" href="#webinars-tab1" role="tab" aria-controls="home" aria-selected="true">live webinars</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-3 pr-2 text-uppercase text-center" id="profile-tab" data-toggle="tab" href="#webinars-tab2" role="tab" aria-controls="profile" aria-selected="false">on demand webinars</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active pt-3" id="webinars-tab1" role="tabpanel" aria-labelledby="home-tab">
                <div class="row d-md-flex fix-min-height-events" *ngIf="WebinarLoaded">
                    <div class="col-md-12 pb-5" *ngFor="let webinarNewsData of WebinarDatas">
                      
                        <a target="_blank" href="live-webinars/{{webinarNewsData.RouteURL}}" class="text-decoration-none">
                            <img class="img-fluid pb-2 image-hight-events" [src]="webinarNewsData.ImageUrl" alt="news image"></a>

                        <a target="_blank" href="live-webinars/{{webinarNewsData.RouteURL}}" class="text-decoration-none">
                            <h4 class="text-height-2 card-header-height-2 text-uppercase" [innerHTML]="webinarNewsData.Name">
                            </h4>
                        </a>
                        <p class="para-2 mb-1"> {{webinarNewsData.DateWithStartTime}} EST </p>
                        <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="webinarNewsData.Details"></p>
                        <a class="para-2 para-2-c-2 " target="_blank" href="live-webinars/{{webinarNewsData.RouteURL}} ">Register Now</a>
                        <!-- <a class="para-2 para-2-c-2 " href="events/live-webinars/{{livewebinarNewsData.RouteURL}}/{{livewebinarNewsData.EventID}} ">Register Now</a> -->

                    </div>
                </div>
                <a routerLink="/events/live-webinars" class="text-center text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 white-color">more Live webinars</a>
            </div>




            <div class="tab-pane fade" id="webinars-tab2" role="tabpanel" aria-labelledby="profile-tab">
                <div class="tab-pane fade show active pt-3" id="webinars-tab1" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row d-md-flex fix-min-height-events" *ngIf="OnDemandWebinarLoaded">
                        <div class="col-md-12 pb-5" *ngFor="let onDemandWebinarData of OnDemandWebinarDatas">
                            <!-- <div class="cursor-p" (click)="redirect(onDemandWebinarData.RouteURL,onDemandWebinarData.EventID)"> -->
                                <a target="_blank" [href]="onDemandWebinarData.RouteURL">
                                    <img class="img-fluid pb-2 image-hight-events" [src]="onDemandWebinarData.ImageUrl" alt="news image">


                                    <h4 class="text-height-2 card-header-height-2 text-uppercase" [innerHTML]="onDemandWebinarData.Name">
                                    </h4>
                                </a>
                                <p class="para-2 mb-1"> {{onDemandWebinarData.Keywords}} </p>
                                <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="onDemandWebinarData.Details"></p>
                                <a class="para-2 para-2-c-2 " target="_blank" [href]="onDemandWebinarData.RouteURL">Watch Now</a>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
                <a routerLink="/events/on-demand-webinars" class="text-center text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3 white-color">more on demand webinars</a>
            </div>
        </div>
    </div>
</div>