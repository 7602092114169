import { Component, OnInit,OnDestroy } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit,OnDestroy {

  interviewLoad:boolean;
  interviewData:any;
  newsLoad:boolean;
  newsData:any;
  upcomingConferencesLoad:boolean;
  upcomingConferencesData:any;
  pastConferencesLoad:boolean;
  pastConferencesData:any;
  LiveWebinarData:any;
  LiveWebinarLoad :boolean;
  OnDemandWebinarData:any;
  OnDemandWebinarLoad :boolean;
  resourcesData:any;
  resourcesLoad :boolean;
  companiesData :any;
  companiesLoad :boolean;
  loaded:boolean;
  spotLightDatas:any;
  firstString:string;
  keyword:any='a';
  envSiteName:any;

  constructor(private route: ActivatedRoute,private routeUrl: Router,private httpclientService: HttpclientService) { 
    this.envSiteName=environment.siteName;
  }

  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  slideInterview = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide next-slide-search'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide prev-slide-search'></div>",
    "dots": false,
    "infinite": true,
    "responsive": [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  slideNews = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide next-slide-search'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide prev-slide-search'></div>",
    "dots": false,
    "infinite": true,
    "responsive": [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  slideCompanies = {
    "slidesToShow": 4, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide next-slide-search'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide prev-slide-search'></div>",
    "dots": false,
    "infinite": true,
    "responsive": [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
 
 

  ngOnInit(): void {
    // const queryParams = this.route.snapshot.queryParams
    // const routeParams = this.route.snapshot.params;
    this.route.params.subscribe(routeParams => {
       this.loadUserDetail(routeParams.id);
    });
    this.spotLightData();
    this.searchInterviews();
    this.searchNews();
    this.searchUpcomingConferences();
    this.searchPastConferences();
    this.searchLiveWebinar();
    this.searchOnDemandWebinar();
    this.searchResources();
    this.searchCompanies();
  }

  searchInterviews(): void { 
    this.interviewLoad = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Search/GetSearchInterviews?siteName='+this.envSiteName+'&Keyword='+this.keyword)
      .subscribe(
        items => {
          this.interviewData = items;
          this.interviewLoad = true;
        }); 
  }

  searchNews(): void { 
    this.newsLoad = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Search/GetSearchNews?siteName='+this.envSiteName+'&Keyword='+this.keyword)
      .subscribe(
        items => {
          this.newsData = items;
          this.newsLoad = true;
        }); 
  }
  searchCompanies(): void { 
    this.companiesLoad = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Search/GetSearchCompanybyvalue?siteName='+this.envSiteName+'&Keyword='+this.keyword)
      .subscribe(
        items => {
          this.companiesData = items;
          this.companiesLoad = true;
      
        }); 
  }
  searchUpcomingConferences(): void { 
    this.upcomingConferencesLoad = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Search/GetSearchEvents?siteName='+this.envSiteName+'&Keyword='+this.keyword)
      .subscribe(
        items => {
          this.upcomingConferencesData = items;
          this.upcomingConferencesLoad = true;
      
        }); 
  }
  searchPastConferences(): void { 
    this.pastConferencesLoad = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Search/GetSearchPastEventsResult?siteName='+this.envSiteName+'&Keyword='+this.keyword)
      .subscribe(
        items => {
          this.pastConferencesData = items;
          this.pastConferencesLoad = true;
      
        }); 
  }
  searchLiveWebinar(): void { 
    this.LiveWebinarLoad = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Search/GetSearchLiveWebinars?siteName='+this.envSiteName+'&Keyword='+this.keyword)
      .subscribe(
        items => {
          this.LiveWebinarData = items;
          this.LiveWebinarLoad = true;
      
        }); 
  }
  searchOnDemandWebinar(): void { 
    this.OnDemandWebinarLoad = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Search/GetSearchOnDemandWebinars?siteName='+this.envSiteName+'&Keyword='+this.keyword)
      .subscribe(
        items => {
          this.OnDemandWebinarData = items;
          this.OnDemandWebinarLoad = true;
        }); 
  }
  searchResources(): void { 
    this.resourcesLoad = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Search/GetSearchResources?siteName='+this.envSiteName+'&Keyword='+this.keyword)
      .subscribe(
        items => {
          this.resourcesData = items;
          this.resourcesLoad = true;
      
        }); 
  }
  spotLightData(): void
  {
    this.loaded =false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetTop1GlobalReourcesSpotLight?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.spotLightDatas = items[0];
        this.loaded = true;
        
    
      }
    );
  }

  loadUserDetail(keyword){

    this.keyword=keyword;
    this.searchInterviews();
    this.searchNews();
    this.searchUpcomingConferences();
    this.searchPastConferences();
    this.searchLiveWebinar();
    this.searchOnDemandWebinar();
    this.searchResources();
    this.searchCompanies();
  }
  
  redirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/events/on-demand-webinars/'+getUrl+"/"+getId]);
    }
  }

  LiveWebinarRedirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/events/live-webinars/'+getUrl+"/"+getId]);
    }
  }

  interviewRedirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/companies/all-c-suiteondeck/'+getUrl]);
    }
  }

  ResourceRedirct(ResourceType:any,getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/resources/'+ResourceType+"/"+getUrl+"/"+getId]);
    }
  }


  UpcommingConferenceRedirect(getUrl:any,getId:any){
    this.firstString = getUrl.substring(0,5);
    if(this.firstString ==="https"){
      window.open(getUrl, "_blank");
    }else{
      this.routeUrl.navigate(['/events/upcoming-conferences/'+getUrl+"/"+getId]);
    }
  }
 
  

  ngOnDestroy(): void {
    // if (this.searchInterviews) {
    // this.interviewData.unsubscribe();
    // }
    // if (this.searchNews) {
    // this.newsData.unsubscribe();
    // }
    // if (this.searchUpcomingConferences) {
    // this.upcomingConferencesData.unsubscribe();
    // }
    // if (this.searchPastConferences) {
    // this.pastConferencesData.unsubscribe();
    // }
    // if (this.searchLiveWebinar) {
    // this.LiveWebinarData.unsubscribe();
    // }
    // if (this.searchOnDemandWebinar) {
    // this.OnDemandWebinarData.unsubscribe();
    // }
    // if (this.searchResources) {
    // this.resourcesData.unsubscribe();
    // }
  }

}
