import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { environment } from '../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';


const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-featured-news',
  templateUrl: './featured-news.component.html',
  styleUrls: ['./featured-news.component.scss']
})
export class FeaturedNewsComponent implements OnInit {

  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 9;

  featureNewsDatas: any= [];
  featureLoaded: boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoadedFeatured: boolean;
  MetaTagDataFeatured: any;


  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string) {
    this.envSiteName=environment.siteName;
   }

   

  ngOnInit(): void {
    this.featuredNews();
    this.AllMetaTagFeatured();
  }

  featuredNews(): void {
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.featureLoaded = false;
    // const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/News/GetAllFutureNewsDetails?siteName='+this.envSiteName+'&pageNumber='+this.page)
      .subscribe(
        items => {
          const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
          this.featureNewsDatas = items;
          this.count=this.featureNewsDatas[0].TotalDataCount;
          this.featureLoaded = true;
          console.log("Future New Data:: ",this.featureNewsDatas);
          this.state.set(STATE_KEY_ITEMS, <any> items);
         
        });       
    
  }

  resetUsers(): void {
    this.featureNewsDatas = null;
    this.featureLoaded = true;
  }

  // on click of pagination tabs
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.featuredNews();
    this.onActivate();
  }

  // for scroll of to top on page change
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
AllMetaTagFeatured(): void {
  if(this.MetaTagLoadedFeatured!=true){
  this.MetaTagLoadedFeatured = false;
  this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Trending News')
  .subscribe(
    items => {
      this.MetaTagDataFeatured = items[0];
      this.MetaTagLoadedFeatured=true;
      console.log('this.MetaTagDataFeatured',this.MetaTagDataFeatured);


        //seo code for tags
        this.titleService.setTitle(this.MetaTagDataFeatured.PageTitle);

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagDataFeatured.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagDataFeatured.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagDataFeatured.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagDataFeatured.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagDataFeatured.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagDataFeatured.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagDataFeatured.OgImageURL}
        );
        //seo code for tags end
      });
    }
}

}
