
<div class="row">
    <div class="col-sm-3">
        <h2 class="text-uppercase mb-0">Events</h2>
    </div>
    <div class="col-sm-8">
        <a href="https://eventually.com/" target="_blank"> <img src="https://abm.report/images/evn-logo.svg" class="eventually-logo"/></a>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <h3>Conferences</h3>

        <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-2 pr-2 text-uppercase text-center" id="home-tab" data-toggle="tab" href="#conferances-tab1" role="tab" aria-controls="home" aria-selected="true">upcoming conferences</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-md-3 pr-md-4 text-uppercase text-center" id="profile-tab" data-toggle="tab" href="#conferances-tab2" (click)="pastConfDataFunction()" role="tab" aria-controls="profile" aria-selected="false">past conferences</a>
            </li>

        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active pt-3" id="conferances-tab1" role="tabpanel" aria-labelledby="home-tab">
                <!-- mobile view slider -->
                <div class="col-12 d-block d-md-none pb-3 p-0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigUpcomingConf" *ngIf="loadedUpcomingConf">
                        <div ngxSlickItem *ngFor="let upcomingConfData of upcomingConfDatas" class="slide" routerLink="upcoming-conferences/{{upcomingConfData.RouteURL}}">
                            <a target="_blank" [href]='upcomingConfData.Url'>
                                <img class="img-fluid pb-2 image-hight-events" [src]="upcomingConfData.ImageUrl" alt="news image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase">{{upcomingConfData.Name}}

                                </h4>
                            </a>
                            <p class="para-2 mb-2">{{upcomingConfData.StartEndDate}} | {{upcomingConfData.Country}}
                                <span *ngIf="upcomingConfData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>
                            </p>
                            <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="upcomingConfData.Details"></p>
                            <a class="para-2 para-2-c-2 " [href]="upcomingConfData.Url">Register Now</a>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!-- mobile view slider end -->
                <div class="row d-none d-md-block fix-min-height-events" *ngIf="loadedUpcomingConf">
                    <div class="col-md-12 pb-5" *ngFor="let upcomingConfData of upcomingConfDatas">
                        <!-- <div class="cursor-p" routerLink='events/upcoming-conferences/{{upcomingConfData.RouteURL}}/{{upcomingConfData.EventID}}'> -->
                        <a target="_blank" [href]='upcomingConfData.Url'>
                            <img class="img-fluid pb-2 image-hight-events" [src]="upcomingConfData.ImageUrl" alt="news image">

                            <h4 class="text-height-2 card-header-height-2 text-uppercase">{{upcomingConfData.Name}}</h4>
                        </a>
                        <p class="para-2 mb-2">{{upcomingConfData.StartEndDate}} | {{upcomingConfData.Country}}
                            <span *ngIf="upcomingConfData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>
                        </p>
                        <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="upcomingConfData.Details"></p>
                        <a class="para-2 para-2-c-2 " [href]="upcomingConfData.Url">Register Now</a>
                        <!-- </div> -->
                    </div>
                </div>
                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3" routerLink="events/upcoming-conferences">more upcoming conferences</button>


            </div>
            <div class="tab-pane pt-3 fade" id="conferances-tab2" role="tabpanel" aria-labelledby="profile-tab">
                <!-- mobile view slider -->
                <div class="col-12 d-block d-md-none pb-3 p-0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigPastConf" *ngIf="loadedPastConf">
                        <div ngxSlickItem *ngFor="let pastConfData of pastConfDatas" class="slide">
                            <!-- <div class="cursor-p" routerLink='events/past-conferences/{{pastConfData.RouteURL}}/{{pastConfData.EventID}}'> -->
                            <a [href]='pastConfData.RouteURL' target="_blank">
                                <img class="img-fluid pb-2 image-hight-events" [src]="pastConfData.ImageUrl" alt="news image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase">{{pastConfData.Name}}

                                </h4>
                            </a>
                            <p class="para-2 mb-2">{{pastConfData.StartDate}}| {{pastConfData.Country}}
                                <span *ngIf="pastConfData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>
                            </p>
                            <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="pastConfData.Details"></p>

                            <!-- </div> -->
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!-- mobile view slider end -->
                <div class="row d-none d-md-block fix-min-height-events" *ngIf="loadedPastConf">
                    <div class="col-md-12 pb-5" *ngFor="let pastConfData of pastConfDatas">
                        <!-- <div class="cursor-p" routerLink='events/past-conferences/{{pastConfData.RouteURL}}/{{pastConfData.EventID}}'> -->
                        <a [href]='pastConfData.RouteURL' target="_blank">
                            <img class="img-fluid pb-2 image-hight-events" [src]="pastConfData.ImageUrl" alt="news image">
                            <h4 class="text-height-2 card-header-height-2 text-uppercase">{{pastConfData.Name}}

                            </h4>
                        </a>
                        <p class="para-2 mb-2">{{pastConfData.StartEndDate}} | {{pastConfData.Country}}
                            <span *ngIf="pastConfData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>
                        </p>
                        <p class="para-1 text-justify text-height-4 card-header-height-3 mb-px-28" [innerHTML]="pastConfData.Details"></p>
                        <!-- </div> -->
                    </div>
                </div>
                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3" routerLink="events/past-conferences">more past conferences</button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <h3 class="mt-4 mt-md-0">Webinars</h3>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-5 pr-5 text-uppercase text-center" id="home-tab" data-toggle="tab" href="#webinars-tab1" role="tab" aria-controls="home" aria-selected="true">live webinars</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-3 pr-2 text-uppercase text-center" id="profile-tab" data-toggle="tab" href="#webinars-tab2" (click)="onDemandWebinarDataFunction()" role="tab" aria-controls="profile" aria-selected="false">on-demand webinars</a>
            </li>

        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active pt-3" id="webinars-tab1" role="tabpanel" aria-labelledby="home-tab">
                <!-- mobile view slider -->
                <div class="col-12 d-block d-md-none pb-3 p-0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigLiveWebiner" *ngIf="loadedLiveWebiner">
                        <div ngxSlickItem *ngFor="let liveWebinarConfData of liveWebinarConfDatas" class="slide">
                            <div class="cursor-p" (click)="redirectLiveWebinar(liveWebinarConfData.RouteURL,liveWebinarConfData.EventID)">
                                <a target="_blank" routerLink='live-webinars/{{liveWebinarConfData.RouteURL}}'>
                                    <img class="img-fluid pb-2 image-hight-events" [src]="liveWebinarConfData.ImageUrl" alt="news image">
                                    <h4 class="text-height-2 card-header-height-2 text-uppercase">{{liveWebinarConfData.Name}}

                                    </h4>
                                </a>
                                <p class="para-2 mb-2">{{liveWebinarConfData.DateWithStartTime}} | {{liveWebinarConfData.Country}}
                                    <span *ngIf="liveWebinarConfData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>
                                </p>
                                <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="liveWebinarConfData.Details"></p>
                                <a class="para-2 para-2-c-2 " (click)="redirectLiveWebinar(liveWebinarConfData.RouteURL,liveWebinarConfData.EventID)">Register Now</a>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!-- mobile view slider end -->
                <div class="row d-none d-md-block fix-min-height-events" *ngIf="loadedLiveWebiner">
                    <div class="col-md-12 pb-5" *ngFor="let liveWebinarConfData of liveWebinarConfDatas">
                        <!-- <div class="cursor-p" routerLink='events/live-webinars/{{liveWebinarConfData.RouteURL}}/{{liveWebinarConfData.EventID}}'> -->
                        <!-- <div class="cursor-p" (click)="redirectLiveWebinar(liveWebinarConfData.RouteURL,liveWebinarConfData.EventID)"> -->
                        <a target="_blank" routerLink='live-webinars/{{liveWebinarConfData.RouteURL}}'>
                            <img class="img-fluid pb-2 image-hight-events" [src]="liveWebinarConfData.ImageUrl" alt="news image">
                            <h4 class="text-height-2 card-header-height-2 text-uppercase">{{liveWebinarConfData.Name}}

                            </h4>
                        </a>
                        <p class="para-2 mb-2">{{liveWebinarConfData.DateWithStartTime}} | {{liveWebinarConfData.Country}}
                            <span *ngIf="liveWebinarConfData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>
                        </p>
                        <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="liveWebinarConfData.Details"></p>
                        <a target="_blank" routerLink='live-webinars/{{liveWebinarConfData.RouteURL}}'>
                            <a class="para-2 para-2-c-2 " (click)="redirectLiveWebinar(liveWebinarConfData.RouteURL,liveWebinarConfData.EventID)">Register Now</a>
                        </a>
                        <!-- </div> -->
                    </div>
                </div>

                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3" routerLink="events/live-webinars">more live webinars</button>
            </div>
            <div class="tab-pane pt-3 fade" id="webinars-tab2" role="tabpanel" aria-labelledby="profile-tab">
                <!-- mobile view slider -->
                <div class="col-12 d-block d-md-none pb-3 p-0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigDemandWebiner" *ngIf="loadedOnDemanadWebiner">
                        <div ngxSlickItem *ngFor="let onDemanadWebinerData of onDemanadWebinerDatas" class="slide">
                            <!-- <div class="cursor-p" (click)="redirect(onDemanadWebinerData.RouteURL,onDemanadWebinerData.EventID)"> -->
                            <a target="_blank" [href]="onDemanadWebinerData.RouteURL">
                                <img class="img-fluid pb-2 image-hight-events" [src]="onDemanadWebinerData.ImageUrl" height="269" alt="news image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase">{{onDemanadWebinerData.Name}}</h4>
                                <p class="para-2 mb-2">{{onDemanadWebinerData.Keywords}}
                                    <span *ngIf="onDemanadWebinerData.IsSponsored"><a> | </a> <font class="para-2-c-2">Sponsored</font></span>
                                </p>
                            </a>
                            <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="onDemanadWebinerData.Details"></p>
                            <a class="para-2 para-2-c-2 " target="_blank" [href]="onDemanadWebinerData.RouteURL">Watch Now</a>
                            <!-- </div> -->
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!-- mobile view slider end -->
                <div class="row d-none d-md-block fix-min-height-events" *ngIf="loadedOnDemanadWebiner">
                    <div class="col-md-12 pb-5" *ngFor="let onDemanadWebinerData of onDemanadWebinerDatas">
                        <div class="cursor-p">
                            <!-- <div class="cursor-p" routerLink='{{onDemanadWebinerData.RouteURL}}'>  -->
                            <a [href]="onDemanadWebinerData.RouteURL" target="_blank">
                                <!-- <a target="_blank" routerLink="/{{onDemanadWebinerData.RouteURL}}/{{onDemanadWebinerData.EventID}}"> -->
                                <img class="img-fluid pb-2 image-hight-events" [src]="onDemanadWebinerData.ImageUrl" height="269" alt="news image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase">{{onDemanadWebinerData.Name}}</h4>
                                <p class="para-2 mb-2">{{onDemanadWebinerData.Keywords}} | Sponsored
                                    <span *ngIf="onDemanadWebinerData.IsSponsored"><a>| </a> <font class="para-2-c-2">Sponsored</font></span>
                                </p>
                            </a>
                            <p class="para-1 text-justify mb-1 text-height-4 card-header-height-3" [innerHTML]="onDemanadWebinerData.Details"></p>
                            <a [href]="onDemanadWebinerData.RouteURL" target="_blank">
                                <a class="para-2 para-2-c-2 " [href]="onDemanadWebinerData.RouteURL" target="_blank">Watch Now</a>
                            </a>
                        </div>
                    </div>
                </div>

                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3" routerLink='events/on-demand-webinars'>more on-demand webinars</button>
            </div>
        </div>
    </div>
</div>