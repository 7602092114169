<p class="mb-1 para-2-c-2">
    <a  routerLink="/">Home</a> > 
    <a  routerLink="/events">Events</a> >
     <a  class="para-2-c-1">Conferences</a>
</p>
<h1 class="text-uppercase mb-0">Conferences</h1>
<div class="row">
    <div class="col-md-12">
        <ul class="nav nav-tabs  w-50" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-2 pr-2 text-uppercase text-center" [class.active]="lastString == 'upcoming-conferences'" id="upcoming-tab" data-toggle="tab" href="#conferances-tab1" routerLink="/events/upcoming-conferences" role="tab" aria-controls="upcoming" aria-selected="true">upcoming conferences</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-md-3 pr-md-4 text-uppercase text-center" [class.active]="lastString == 'past-conferences'" id="past-tab" data-toggle="tab" href="#conferances-tab2" routerLink="/events/past-conferences" role="tab" aria-controls="past" aria-selected="true">past conferences</a>
            </li>

        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade mt-3" [ngClass]="{'active': lastString == 'upcoming-conferences', 'show': lastString == 'upcoming-conferences'}" id="conferances-tab1" role="tabpanel" aria-labelledby="upcoming-tab">
                <app-upcoming-conferences></app-upcoming-conferences>
            </div>
            <div class="tab-pane mt-3 fade" [ngClass]="{'active': lastString == 'past-conferences', 'show': lastString == 'past-conferences'}" id="conferances-tab2" role="tabpanel" aria-labelledby="past-tab">
                <app-past-conferences></app-past-conferences>
            </div>
        </div>
    </div>
</div>