<div class="container">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h1 class="text-main">PRIVACY POLICY</h1>
        <p>{{envSiteName}} ({{preSite}}) is committed to protecting the privacy of the companies that register and use our Website (“Users”) and the information they provide to us. This Privacy Policy outlines important information regarding the use and disclosure of all User information provided to {{preSite}}’s Website. </p>
        <p>{{preSite}} provides this Privacy Policy to help Users make an informed decision as to whether to use or continue using the Website. If you have questions or concerns, contact {{preSite}} Support at <a href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>. By reference, this Privacy Policy is incorporated into and is subject to any and all agreements entered into between Users and {{preSite}}.</p>
        <p>Your use of the Website and any information you provide via the Website or through other official {{preSite}} contact remains subject to this Privacy Policy, as well as any other governing {{preSite}} policies. You are informed that any content posted by Users onto the Website becomes published or informational content, or the like, and is not considered personally identifiable information subject to this Privacy Policy.</p>
        <h4 class="text-main" >Collected Information</h4>
        <p>{{preSite}} may collect personal information and data including, but not limited to, application and Website usage data, file transfer and viewing data, email and personal contact information, and other identifiable information provided to us by our Users. {{preSite}} may use technological means for tagging and tracking data including, but not limited to, harmless cookies, clear GIFs, and other technologies to track and correlate data. Specifically, but not limited to, the following information is collected: </p>
        <p><b>1.	User Provided Data:</b> Users may provide personally identifiable information (such as a name, credit card data, address, telephone, and an email address) as well as sensitive information (such as Tax ID or Social Security Number) to {{preSite}} when choosing to participate in the services and activities available on the Website (collectively, the "Services"). </p>
        <p><b>2.	Cookies:</b> Cookies, a harmless small text file containing a string of alphanumeric characters, are deployed to your computer so as to uniquely identify your presence and use. {{preSite}} may use session cookies and persistent cookies for the purpose of tracking various important data. Use of these cookies can also help to speed your access to the Website as they serve as 'reminders' as to who you are to our programming. While we recommend that you allow these cookies to perform their tasks, you may adjust your computer's settings to restrict or refuse them. However, some features or sections of the Website may not function properly, or not all, if the ability to accept cookies is disabled. We do not link the information we store in cookies to any personally identifiable information you submit while on our site.</p>
        <p><b>3.	Clear GIFs:</b> {{preSite}} may choose to utilize "clear GIFs" to track the online usage patterns of our Users in a non-personally identifiable manner. Additionally, {{preSite}} may also use clear GIFs in HTML-based emails dispatched to Users so as to track which emails are being opened by the recipients. This act helps {{preSite}} to determine active and inactive email addresses. </p>
        <p><b>4.	Log File Data:</b> When Users log-in to their {{preSite}} account, our servers will automatically record and archive certain information that web-browsers send whenever visiting a website. These server logs may include information vital to validating the User's authorization to access the account. Information, such as a Web request, Internet Protocol (the "IP") address, browser type, browser language, referring pages, exit pages and visited URLs, platform type, click counts, pages viewed and in what order, time spent, the date and time of the request, and other important data is necessary to validate and authorize a User's entry and activity on the Website.</p>
        <p>{{preSite}} may collect information from other sources to help us correct or supplement our records, improve the quality or personalization of our service to you, and prevent or detect fraud. We work closely with third parties (for example, business partners, service providers, sub-contractors, advertising networks, analytics providers, search information providers, fraud protection services) and may receive information about you from them. </p>
        <p>Further, and in order to provide the services and improve {{preSite}}’s websites, we may engage the services of third-party vendors. In the process of supplying services to {{preSite}}, these third-party vendors may need to collect Personal Information about you. </p>
        <h4 class="text-main" >Information Uses</h4>
        <p>1.	Personally identifiable information submitted by a User is used to operate, maintain, and provide features, general and unique, of the Website for the User's use and for to provide its Services.</p>
        <p>2.	All personal information, data, or content that a User voluntarily discloses to the Website becomes available to other Users, consistent with the Services being provided by {{preSite}}, and can be collected and used by other authorized Users. Your account Username is displayed to other Users when you negotiate pricing, edit campaign information, or upload creative through the Website.</p>
        <p>3.	{{preSite}} does use personally identifiable information and certain non-personally identifiable information, such as but not limited to, anonymous User usage data, browser type, cookies, IP addresses, clickstream data, and the like, to improve the quality and use designs of the Website through analysis of this data and trends. </p>
        <p>4.	{{preSite}} uses clear GIFs and log file data to: </p>
        <p>i.	provide custom content and preferences; <br>
        ii.	remember information necessary for logging in to your Website account so as to save you time in re-entering such information; <br>
        iii.	monitor the impact and effectiveness of {{preSite}} marketing efforts;<br>
        iv.	monitor aggregate metrics providing detailed data on User activities while on the Website; <br>
        v.	track your submissions, entries, and status.</p>
        <p>5.	Users have the ability to indirectly communicate with each other via dashboard activities. These activities include but are not limited to: negotiation of lead pricing; the start, pausing, and stopping of a campaign; the ability to upload campaign creative; and editing of campaign criteria. </p>
        <h4 class="text-main" >Disclosure of Information</h4>                    
        <p>1.	{{preSite}} will not rent or sell your Personal Information to others but may disclose personal information with third-party vendors and service providers that work with {{preSite}}. We will only share personal information to these vendors and service providers to help us provide a product or service to you. Examples of third parties we work with are call center operators, shippers, servicers, information processors, financial institutions, data appending and surge scoring companies. These third parties only have access to personal information necessary for them to complete their service. </p>
        <p>2.	{{preSite}} provides personally identifiable and non-personally identifiable information on Users to affiliated companies, and other businesses or persons for the purpose of processing such information on behalf of {{preSite}}. {{preSite}} uses third parties such as a credit card processing company to bill Users for transactions, and a credit scoring company to determine business credit scores (when applicable, for extending credit terms to Users upon request). At all times, these parties are required to agree to process such information in compliance with this Privacy Policy, and we deploy reasonable efforts to limit their use of such information.</p>
        <p>3.	{{preSite}} reserves the right to disclose personally identifiable and/or non-personally identifiable information that is believed, in good faith, to be appropriate or necessary for the purpose of enforcing the {{preSite}} Terms of Service, or other governing {{preSite}} policies. Other causes of release of data may be due to, but not limited to: </p>
        <p>i.	taking precautions against liability issues;
        <br>
        ii.	to assist government enforcement agencies; <br>
        iii.	to investigate and defend {{preSite}} against third party claims or allegations; 
        <br>
        iv.	to protect the security or integrity of the Website and/or Services; and/or
        <br>
        v.	to protect the rights, property, or personal safety of {{preSite}}, Users of the Website, or others.
        </p>
        <p>4.	{{preSite}} does not share personally identifiable information with other non-affiliated third-party companies for their commercial use or marketing use without your consent, except as part of Services.</p>
        <p>5.	{{preSite}} does share non-personally identifiable information, such as anonymous User data and traffic data, with interested third parties to assist them in understanding the usage patterns and trends analysis for certain content, advertisements, promotions, services, and/or functionality of the Website. </p>
        <p>6.	{{preSite}} may release any information available if required to do so by law, or in the good faith belief that such action is necessary to comply with law or the powers of government enforcement agencies. Other causes for release of information could be for needs to comply with copyright laws or to respond to a court order, subpoena, and/or search warrant. We also may be required to disclose an individual’s personal information in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.</p>
        
        <p><b>Decline to Accept:</b> You may decline to submit or allow the submission of personally identifiable information through the Website, in which case {{preSite}} may not be able to provide certain Services. You may, at any time, update or edit your profile information and email preferences by visiting your {{preSite}} account profile page.</p>
        <p><b>Links and Third-Party Advertisers:</b> {{preSite}} allows other companies, also referred to as Third Party Advertisers, Ad Servers, or Ad Networks (collectively, the "Advertisers") to serve advertisements via the Website. These Advertisers may use technology to send directly to your Web browser advertisements and links that appear on the Website. This technology may capture certain non-personally identifiable data, such as your IP address, etc. This technology may also measure the effectiveness of their advertisements and to personalize the advertising content you may view on the Website. </p>
        <p>{{preSite}} does not provide any personally identifiable information to these Advertisers without your consent, except as part of a specific program, feature, or campaign for which you will have the ability to opt-out. However, should you click on an Advertiser offering or choose to visit an Advertiser's website, {{preSite}} highly recommends that you consult the respective privacy policy of the Advertiser for more information on their practices and for instructions on how to opt-out of certain programs and practices of the Advertiser. {{preSite}}'s Privacy Policy does not apply to, nor can {{preSite}} control, the activities of other websites or Advertisers. </p>
        <p><b>Data Security:</b> In an effort to provide maximum protection of User's privacy, {{preSite}} routinely utilizes commercially reasonable physical, technical, and managerial safeguards to preserve the integrity and security of your personal information. However, {{preSite}} cannot ensure or warrant the security of any information you transmit to {{preSite}} or place upon the Website and you do so at your own risk. Once {{preSite}} receive said transmission of personal information, {{preSite}} engages commercially reasonable efforts to ensure the security of its systems and the personal information you provided. This is not a guarantee that such information may not be accessed, altered, disclosed, or destroyed by breach of any of our physical, technical, or managerial safeguards. The security of your personal information is important to us. When you enter sensitive information (such as social security number or Tax ID) on our registration or order forms, we encrypt that information using secure socket layer technology (SSL). </p>
        
        
                      
       
       
        <p>
        {{preSite}} commits to resolve complaints about your privacy and our collection or use of your personal information. Individuals with inquiries or complaints regarding this privacy policy should first contact {{preSite}} Attention: Information Security Department, 4th Floor, Alpha 2 Giga Space, Viman Nagar Pune, Maharashtra 411014 INDIA, Email: <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>, Direct Dial (International): (1) 844-900-9595.</p>
      

        
        <h4 class="text-main" >California Privacy Rights </h4>
        <p>California Civil Code Section 1798.83 permits individual California residents to request certain information regarding {{preSite}}'s disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please write to <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>. Please include your name, mailing address, and email address if you want to receive a response by email. Otherwise, we will respond by postal mail within the time required by applicable law. </p>
        <p>If you are a California resident under age 18 and are a registered user of any of the services, then you may request that we remove any content that you created and publicly posted on our website ("User Content"). To request removal of your User Content, please send an email with a detailed description of the specific Data User Content to <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>. </p>
        
        <h4 class="text-main" >Access</h4>
        <p>{{preSite}} reserves the right to request that you provide information that will enable us to confirm that the User Content that you want removed was created and posted by you. {{preSite}} will make a good faith effort to delete or remove your User Content from public view as soon as reasonably practicable. Please note, however, that your request that we delete your User Content does not ensure complete or comprehensive removal of your User Content. Your User Content may remain on backup media, cached or otherwise retained by {{preSite}} for administrative or legal purposes or your User Content may remain publicly available if you or someone else has forwarded or re-posted your User Content on another website or service prior to its deletion. {{preSite}} may also be required by law to not remove (or allow removal) of your User Content. Updating or Correcting Personally Identifiable Information if you no longer desire to use {{preSite}}’s Services or need to update your contact information, you may correct or update it by emailing our privacy team at <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>, or by contacting us by telephone or postal mail at the contact information listed below. Your rights to access, to limit use, and to limit disclosure: European Union individuals have the right to access personal data about them, and to limit use and disclosure of their personal data. If you wish to request access, to limit use, or to limit disclosure, please email {{preSite}} privacy team at <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a> or send a written request to the contact information provided below. </p>
        <p><b>Children's Privacy:</b> {{preSite}} complies with the Children's Online Privacy Protection Act of 1998 (COPPA) and does recognize the responsibility to protect the privacy of young children at all times and to the best of everyone's ability. For that reason, {{preSite}} does not knowingly collect or maintain any information via the Website from persons under the age of thirteen (13), and no part of our Website is directed to persons under the age of thirteen (13). If an underage person provides such information and {{preSite}} has unknowingly collected said information, then {{preSite}} will take the appropriate steps to delete this information immediately. </p>
        <p><b>Transfer of Rights:</b> Should {{preSite}} be acquired, merged, or endeavor to become closed for business, {{preSite}} reserves the right, in any of these circumstances, to transfer or assign the information collected, during the due course of business, from Users of the Website. This information may be held as part of any such acquisition, merger, sale, or other change of control or business entity status. In the unlikely event of a bankruptcy, assignment for the benefit of creditors, insolvency, receivership, reorganization, or the application of laws or legally recognized enforcements and/or actions, {{preSite}} may not be able to control how Users' personal information is accessed, treated, transferred, and/or used. In any of these circumstances, you hold harmless and indemnify {{preSite}} and its officers, directors, employees, agents, and assignees from and against any claims of responsibility, damages, costs, debts, losses, liabilities, obligations, fees and/or expenses. </p>
        <p><b>Contact Information:</b> You may contact {{preSite}} Customer Service at: {{preSite}} Attention: 4th Floor, Alpha 2 Giga Space, Viman Nagar Pune, Maharashtra 411014 INDIA, Email: <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>, Direct Dial (International): (1) 844-900-9595.</p>
        <p><b>Changes and Updates:</b> If we decide to change our privacy policy, we will post those changes to this privacy policy on our home page, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this privacy policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our home page. </p>
        <p><b>Communications:</b> On occasion as deemed necessary by {{preSite}} (daily, weekly, monthly, etc.), {{preSite}} will send the User various communications as part of the Service, such as account activity alerts, account updates, and campaigns targeted to the criteria of the User, as determined by {{preSite}}. Said communications shall originate from {{preSite}} only and shall be conducted in compliance to the Section entitled "Disclosure of Information", of this Privacy Policy. At any time, the User may entirely opt-out of such communications by emailing Customer Service at <a style="color:#891a2b" href="mailto:privacy@{{envSiteName | lowercase}}">privacy@{{envSiteName | lowercase}}</a>. </p>
        <p><b>Presiding Language:</b> This Agreement and all {{preSite}} policies are written and executed in English, which is considered the prevailing language for purposes of interpretation or laws of contract. If this Agreement is translated into any other language, the English version shall be considered the primary binding legal document.</p>
        





        
    </div>
</div>